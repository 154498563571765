import Hero from './components/Hero';
import Activities from './components/Activities';
import SportsGallery from './components/SportsGallery';
import Benefits from './components/Benefits';
import News from './components/News';
import Gallery from './components/Gallery';
import About from './components/About';
import VideoBanner from './components/VideoBanner';
import Social from './components/Social';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Accordion from './components/Accordion';
import Aboutdos from './components/Aboutdos';
import WhatsappButton from './components/WhatsappButton';


function App() {

  return (
    <>
    <main>
      <article>
        <Hero />
        <Activities />
        <SportsGallery />
        <Benefits />
        <News />
        <Gallery />
        <About />
        <Aboutdos />
        <Accordion />
        <VideoBanner />
        <Contact />
      </article>
    </main>
      <Social />
      <Footer />
      <WhatsappButton />
    </>
  );
}

export default App;
