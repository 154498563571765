
export default function WhatsappButton() {
  return (
    <>
        <a
            href="https://api.whatsapp.com/send?phone=5492644899032"
            target="_blank"
            rel="noopener noreferrer"
            className="wht__btn"
            aria-label="Boton Whatsapp, Chatea con nosotros en WhatsApp"
            title="Chatea con nosotros en WhatsApp"
        >
            <ion-icon name="logo-whatsapp"></ion-icon>
        </a>
    </>
  )
}
