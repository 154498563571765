import hockey from '../assets/images/escudos/hockey-vortex.png'
import futbol from '../assets/images/escudos/futbol-soccer-vortex.png'
import basquet from '../assets/images/escudos/basquet-vortex.png'
import voley from '../assets/images/escudos/voley-vortex.png'
import tenis from '../assets/images/escudos/tenis-vortex.png'
import natacion from '../assets/images/escudos/natacion-pileta-solarium-vortex.png'
import gym from '../assets/images/escudos/gimnasio-gym-vortex.png'

export default function SportsGallery() {
  return (
    <section className="section sports__gallery" aria-label="Galería de Deportes">
        <div className="container">

          <ul className="has__scrollbar">

            <li className="sports__gallery-item">
              <img
              src={hockey}
              width="90"
              height="90"
              loading="lazy"
              alt="hockey césped patines vortex"/>
              <p style={{ padding: '10px', fontSize: 'medium', color: 'white', textAlign: 'center' }}>HOCKEY</p>
            </li>

            <li className="sports__gallery-item">
              <img
              src={futbol}
              width="90"
              height="90"
              loading="lazy"
              alt="futbol soccer vortex"/>
              <p style={{ padding: '10px', fontSize: 'medium', color: 'white', textAlign: 'center' }}>FUTBOL</p>
            </li>

            <li className="sports__gallery-item">
              <img
              src={basquet}
              width="90"
              height="90"
              loading="lazy"
              alt="basquet vortex"/>
              <p style={{ padding: '10px', fontSize: 'medium', color: 'white', textAlign: 'center' }}>BASQUET</p>
            </li>

            <li className="sports__gallery-item">
              <img
              src={voley}
              width="90"
              height="90"
              loading="lazy"
              alt="voley vortex"
              />
              <p style={{ padding: '10px', fontSize: 'medium', color: 'white', textAlign: 'center' }}>VOLEY</p>
            </li>

            <li className="sports__gallery-item">
              <img
              src={tenis}
              width="90"
              height="90"
              loading="lazy"
              alt="tenis escuela vortex"
              />
              <p style={{ padding: '10px', fontSize: 'medium', color: 'white', textAlign: 'center' }}>TENIS</p>
            </li>

            <li className="sports__gallery-item">
              <img
              src={natacion}
              width="90"
              height="90"
              loading="lazy"
              alt="natación pileta solarium vortex"
              />
              <p style={{ padding: '10px', fontSize: 'medium', color: 'white', textAlign: 'center' }}>NATACION</p>
            </li>

            <li className="sports__gallery-item">
              <img
              src={gym}
              width="90"
              height="90"
              loading="lazy"
              alt="gimnasio gym vortex"
              />
              <p style={{ padding: '10px', fontSize: 'medium', color: 'white', textAlign: 'center' }}>GYM</p>
            </li>

          </ul>

        </div>
      </section>
  )
}
