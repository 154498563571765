/* eslint-disable jsx-a11y/anchor-is-valid */
// /* eslint-disable jsx-a11y/anchor-is-valid */ --> revisar
import ActivitiesHockey from '../assets/images/actividades/actividades-hockey.png'
import Activities1 from '../assets/images/actividades/actividades-1.jpg'
import Activities2 from '../assets/images/actividades/actividades-2.jpg'
import Activities3 from '../assets/images/actividades/actividades-3.jpg'


export default function Activities() {
  return (
    <section className="section activities" id="activities" aria-label="sección actividades">
        <div className="container">

          <h2 className="h2 section__title">
            Todas las <span className="span">Actividades</span>
          </h2>

          <ul className="has__scrollbar">

            <li className="scrollbar__item">
              <div className="activities__card">

                <figure className="card__banner img__holder" style={{"--width": "450", "--height": "600"}}>
                  <img
                  src={ActivitiesHockey}
                  width="450"
                  height="600"
                  loading="lazy"
                  alt="actividades Torneos Deportivos"
                  className="img__cover"/>
                </figure>

                <div className="card__content">

                  <h3 className="h3">
                    <a className="card__title" tabIndex="-1">
                      Torneos <span className="span">Deportivos</span>
                    </a>
                  </h3>

                </div>

                <div className="card__content-overlay">

                  <h3 className="h3">
                    <a className="card__title">
                      Torneos <span className="span">Deportivos</span>
                    </a>
                  </h3>

                </div>

              </div>
            </li>

            <li className="scrollbar__item">
              <div className="activities__card">

                <figure className="card__banner img__holder" style={{"--width": "450", "--height": "600"}}>
                  <img
                  src={Activities1}
                  width="450"
                  height="600"
                  loading="lazy"
                  alt="actividades Acondicionamiento Físico"
                  className="img__cover"/>
                </figure>

                <div className="card__content">

                  <h3 className="h3">
                    <a className="card__title" tabIndex="-1">
                      Acondicionamiento <span className="span">Físico</span>
                    </a>
                  </h3>

                </div>

                <div className="card__content-overlay">

                  <h3 className="h3">
                    <a className="card__title">
                      Acondicionamiento <span className="span">Físico</span>
                    </a>
                  </h3>

                </div>

              </div>
            </li>

            <li className="scrollbar__item">
              <div className="activities__card">

                <figure className="card__banner img__holder" style={{"--width": "450", "--height": "600"}}>
                  <img
                  src={Activities2}
                  width="450"
                  height="600"
                  loading="lazy"
                  alt="actividades Eventos Sociales y Culturales"
                  className="img__cover"/>
                </figure>

                <div className="card__content">

                  <h3 className="h3">
                    <a className="card__title" tabIndex="-1">
                      Eventos Sociales y <span className="span">Culturales</span>
                    </a>
                  </h3>


                </div>

                <div className="card__content-overlay">

                  <h3 className="h3">
                    <a className="card__title">
                      Eventos Sociales y <span className="span">Culturales</span>
                    </a>
                  </h3>

                </div>

              </div>
            </li>

            <li className="scrollbar__item">
              <div className="activities__card">

                <figure className="card__banner img__holder" style={{"--width": "450", "--height": "600"}}>
                  <img
                  src={Activities3}
                  width="450"
                  height="600"
                  loading="lazy"
                  alt="actividades Actividades al Aire Libre"
                  className="img__cover"/>
                </figure>

                <div className="card__content">

                  <h3 className="h3">
                    <a className="card__title" tabIndex="-1">
                      Actividades al Aire <span className="span">Libre</span>
                    </a>
                  </h3>

                </div>

                <div className="card__content-overlay">

                  <h3 className="h3">
                    <a className="card__title">
                      Actividades al Aire <span className="span">Libre</span>
                    </a>
                  </h3>

                </div>

              </div>
            </li>

          </ul>

        </div>
      </section>
  )
}
