import vortexBg from '../assets/images/vortex-bg.svg';
import hero from '../assets/images/escudo-700.png'

// import Timer from './Timer';

export default function Hero() {
  
  return (
    <section
        className="section hero"
        id="home"
        aria-label="sección inicio"
        style={{ backgroundImage: `url(${vortexBg})` }}
        >

          {/* <div
            className=""
            aria-label="reloj cuenta regresiva"
            title="reloj"
            style={{ width: "100%" }}
          > */}

            {/* <Timer /> */}

          {/* </div> */}

        <div className="container">


          <div className="hero__content">

            <p className="hero__subtitle">Viví la Experiencia</p>

            <h1 className="h1 hero__title">
              Club <span className="span">VORTEX</span> Deportivo
            </h1>

            <p className="hero__text">
            Donde la diversión y la pasión dan nueva vida a este lugar. Completa el formulario de inscripción y unite a Vortex. Accede a eventos épicos, beneficios exclusivos y la emoción de ser parte de algo extraordinario. ¡Hace click ahora para unirte a Vortex!
            </p>

            {/* <button className="btn skewBg"><a href="https://forms.gle/1XsLcgPpVrfvk81L6" target="_blank" rel="noreferrer">Quiero Ser Socio</a></button> */}

            <button onClick={() => window.open("https://forms.gle/1XsLcgPpVrfvk81L6", "_blank")} className="btn skewBg btn__trans"
            aria-label="boton quiero ser socio"
            title='formulario de inscripción'
            >
              Quiero Ser Socio
            </button>

          </div>

          <figure className="hero__banner img__holder" style={{ "--width": "700px", "--height": "700px" }}>
            <img src={hero} width="700" height="700" alt="escudo hero vortex" className="w-100" style={{padding: "30px"}}/>
            <figcaption
            className="flicker-5"
            style={{ textAlign:"center", fontFamily:"inherit", fontWeight:"bold", fontSize:"1.7rem", fontStyle:"italic", color:"var(--vortex-orange)" }}
            >
              MARCAMOS EL CAMINO HACIA EL FUTURO
            </figcaption>
          </figure>

        </div>

      </section>
  )
}
