import { useEffect } from "react";
import { Link } from "react-router-dom";
import YouTube from 'react-youtube';

import {
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  XIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon
} from "react-share";
import Footer from "../Footer"


import image01 from '../../assets/images/news/new-03/apertura-vortex-club-01-min.png';
// import image02 from '../../assets/images/news/new-03/apertura-vortex-club-02-min.png';
// import image03 from '../../assets/images/news/new-03/apertura-vortex-club-03-min.png';

export default function NewTwo() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const shareUrl = 'https://vortexclubdeportivo.com.ar/noticias/gran-apertura-15-de-diciembre-club-vortex-deportivo';
  const shareTitle = 'Inauguramos nuestro club, gran apertura.';

  return (
    <>
 <section className="activities">

<div className="container">

  <img
    className="img__cover img__holder"
    loading="lazy"
    src={image01}
    alt="vortex club noticias, inauguración, apertura 15 de diciembre, deportivo, king of banana, acto del Ministerio de Turismo, Cultura y Deportes, junto a la Secretaría de Deportes, Guido Romero, Pablo Tabachnik, Rubén Arroyo, Rivadavia, San Juan."
    style={{marginTop:"5rem", aspectRatio: "16/9"}}
    />

<h1 className="section__title" style={{ color:"black", marginTop:"100px" }}>
Inauguramos nuestro club
</h1>
<h2>
Nos complace anunciar nuestra apertura tras una emocionante fase de renovación.
</h2>

<p style={{textAlign:"start",color:"black", fontFamily:"inherit", fontSize:"1.8rem", paddingTop:"1.5rem", paddingBottom:"2rem"}}>
Este evento marca un hito significativo en la evolución del club, ofreciendo a la comunidad un espacio deportivo y social renovado y moderno.
</p>

<p style={{textAlign:"start",color:"black", fontFamily:"inherit", fontSize:"1.8rem", paddingTop:"1.5rem", paddingBottom:"2rem"}}>
En el video adjunto, capturamos los momentos más destacados del evento de inauguración. Desde la emotiva ceremonia de corte de cinta hasta las primeras impresiones de los miembros fundadores, queremos compartir este nuevo capítulo con nuestra comunidad.
</p>

<p style={{textAlign:"start",color:"black", fontFamily:"inherit", fontSize:"1.8rem", paddingTop:"1.5rem", paddingBottom:"2rem"}}>
Agradecemos a todos los que han sido parte de este viaje y esperamos que disfruten de este vistazo exclusivo a nuestro nacimiento. Esperamos con entusiasmo crear recuerdos y experiencias inolvidables en esta nueva etapa que estamos escribiendo juntos.
</p>

<p style={{textAlign:"start",color:"black", fontFamily:"inherit", fontSize:"1.8rem", paddingTop:"1.5rem", paddingBottom:"2rem"}}>
Para más información y consultas, por favor contactar a:
<br/>
Enrique Arroyo - 2644899032
<br/>
info@vortexclubdeportivo.com.ar
</p>

<p style={{textAlign:"start",color:"black", fontFamily:"inherit", fontSize:"1.8rem", paddingTop:"1.5rem", paddingBottom:"2rem"}}>
Gracias por ser parte de esta emocionante apertura. ¡Nos vemos en el club!
</p>

<YouTube
title="Vortex Club - Gran Apertura"
loading="lazy"
  style={{
    marginBottom: '5rem',
    aspectRatio: '16 / 9',
  }}
  videoId="eYq5oqMe_AM"
  opts={{
    width: '100%',
    height: '100%',
    playerVars: {
      autoplay: 1,
      origin: window.location.origin,
    },
  }}
  onReady={e => e.target.pauseVideo()}
/>

<h4>Compartir en:</h4>
    <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '300px', gap:"0.5rem", marginBottom:"3rem" }}>


      <WhatsappShareButton url={shareUrl} title={shareTitle}>
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>

      <FacebookShareButton url={shareUrl} quote={shareTitle} hashtag="#vortexclub">
        <FacebookIcon size={32} round />
      </FacebookShareButton>

      <TwitterShareButton url={shareUrl} title={shareTitle}>
        <XIcon size={32} round />
      </TwitterShareButton>

      <TelegramShareButton url={shareUrl} title={shareTitle}>
        <TelegramIcon size={32} round />
      </TelegramShareButton>

      <LinkedinShareButton url={shareUrl} title={shareTitle} summary="Inauguramos nuestro club, gran apertura.">
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>

      <EmailShareButton url={shareUrl} subject={shareTitle} body="Inauguramos nuestro club, gran apertura.">
        <EmailIcon size={32} round />
      </EmailShareButton>


    </div>

    <Link to="/" className="btn contact__wrapper-btn skewBg">
      Volver al inicio
    </Link>

    </div>
    </section>
    <Footer />
    </>
  )
}
