import fachada from '../assets/images/galeria/fachada.png'
import fachada1 from '../assets/images/galeria/fachada-1.png'
import fachada2 from '../assets/images/galeria/fachada-2.png'
import fachada3 from '../assets/images/galeria/fachada-3.png'
import fachada4 from '../assets/images/galeria/fachada-4.png'
import fachada5 from '../assets/images/galeria/fachada-5.png'

export default function Gallery() {
  return (
    <section className="section gallery" id="gallery" aria-label="sección galería">
        <div className="container">

          <h2 className="h2 section__title">
            Nuestra <span className="span">Galería</span>
          </h2>

          <p className="section__text">
            Descubre Vortex a través de imágenes y videos. Explora nuestros momentos más destacados y eventos emocionantes capturados en esta galería. 
          </p>

          <div className="container__gallery">
          
            <div className="imgBx">
              <img
                src={fachada}
                alt="fachada club vortex"
              />
              <div className="contentBx">
                <div className="box sm-box"><p>IMAGEN</p></div>
              </div>
            </div>
            <div className="imgBx">
              <img
                src={fachada1}
                alt="estacionaminto vortex"
                />
              <div className="contentBx">
                <div className="box sm-box"><p>IMAGEN</p></div>
              </div>
            </div>
            <div className="imgBx">
              <img
                src={fachada2}
                alt="mesa de entrada vortex"
                />
              <div className="contentBx">
                <div className="box sm-box"><p>IMAGEN</p></div>
              </div>
            </div>
            <div className="imgBx">
              <img
                src={fachada3}
                alt="cartel vortex"
                />
              <div className="contentBx">
                <div className="box sm-box"><p>IMAGEN</p></div>
              </div>
            </div>
            <div className="imgBx">
              <img
                src={fachada4}
                alt="ilumanación cartel vortex"
                />
              <div className="contentBx">
                <div className="box sm-box"><p>IMAGEN</p></div>
              </div>
            </div>
            <div className="imgBx">
              <img
                src={fachada5}
                alt="garita vortex"
                />
              <div className="contentBx">
                <div className="box sm-box"><p>IMAGEN</p></div>
              </div>
            </div>
          </div>

        </div>
      </section>
  )
}
