/* eslint-disable jsx-a11y/anchor-is-valid */
import instlaciones from '../assets/images/beneficios/instalaciones.jpg'
import eventos from '../assets/images/beneficios/eventos-competencias.jpg'
import coaching from '../assets/images/beneficios/entrenamiento-coaching.jpg'
import comunidad from '../assets/images/beneficios/comunidad-beneficios-sociales.jpg'

export default function Benefits() {
  return (
    <section className="section benefits" id="benefits" aria-label="sección beneficios">
        <div className="container">

          <h2 className="h2 section__title">
            Nuestros <span className="span">Beneficios</span>
          </h2>

          <p className="section__text">
            Ser Socio significa acceso a ventajas exclusivas. Disfruta de eventos prioritarios, descuentos especiales y mucho más. Hacete Socio y comenzá a disfrutar los beneficios hoy mismo.
          </p>

          <ul className="has__scrollbar">

            <li className="scrollbar__item">
              <div className="benefits__card">

                <figure className="card__banner img__holder" style={{"--width": "300", "--height": "260"}}>
                  <img
                  src={instlaciones}
                  width="300"
                  height="260"
                  loading="lazy"
                  alt="instalaciones"
                  className="img__cover"/>
                </figure>

                <div className="card__content">

                  <h3 className="h3">
                    <a className="card__title">Instalaciones</a>
                  </h3>

                  <div className="card__wrapper">
                    <p className="card__inst">Acceso a Instalaciones</p>

                  </div>

                </div>

              </div>
            </li>

            <li className="scrollbar__item">
              <div className="benefits__card">

                <figure className="card__banner img__holder" style={{"--width": "300", "--height": "260"}}>
                  <img
                  src={eventos}
                  width="300"
                  height="260"
                  loading="lazy"
                  alt="eventos y competencias"
                  className="img__cover"
                  />
                </figure>

                <div className="card__content">

                  <h3 className="h3">
                    <a  className="card__title">Exclusivos</a>
                  </h3>

                  <div className="card__wrapper">
                    <p className="card__inst">Eventos y Competencias</p>

                  </div>

                </div>

              </div>
            </li>

            <li className="scrollbar__item">
              <div className="benefits__card">

                <figure className="card__banner img__holder" style={{"--width": "300", "--height": "260"}}>
                  <img
                  src={coaching}
                  width="300"
                  height="260"
                  loading="lazy"
                  alt="entrenamiento y coaching profesional"
                  className="img__cover"
                  />
                </figure>

                <div className="card__content">

                  <h3 className="h3">
                    <a className="card__title">Profesional</a>
                  </h3>

                  <div className="card__wrapper">
                    <p className="card__inst">Entrenamiento y Coaching</p>

                  </div>

                </div>

              </div>
            </li>

            <li className="scrollbar__item">
              <div className="benefits__card">

                <figure className="card__banner img__holder" style={{"--width": "300", "--height": "260"}}>
                  <img
                  src={comunidad}
                  width="300"
                  height="260"
                  loading="lazy"
                  alt="comunidad beneficios sociales"
                  className="img__cover"
                  />
                </figure>

                <div className="card__content">

                  <h3 className="h3">
                    <a className="card__title">Comunidad</a>
                  </h3>

                  <div className="card__wrapper">
                    <p className="card__inst">Beneficios Sociales</p>
                  </div>

                </div>

              </div>
            </li>

          </ul>

        </div>
        </section>
  )
}
