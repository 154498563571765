import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import './assets/css/style.css';
import Navbar from './components/Navbar';
import App from './App';
import reportWebVitals from './reportWebVitals';
import NewOne from './components/news/NewOne';
import NewTwo from './components/news/NewTwo';
import NewThree from './components/news/NewThree';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

      <BrowserRouter>
            <Navbar />
        <Routes>
          <Route path="/" >
            <Route index element={ <App /> } />
            <Route path='noticias/buenos-vecinos-iniciamos-campañas-de-acercamiento-a-la-comunidad' element={ <NewOne />} />
            <Route path='noticias/presentamos-nuestro-club-en-conferencia-de-prensa' element={ <NewTwo />} />
            <Route path='noticias/gran-apertura-15-de-diciembre-club-vortex-deportivo' element={ <NewThree />} />

            <Route path="*" element={ <Navigate replace to="/" /> } />
          </Route>
        </Routes>
      
      </BrowserRouter>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
