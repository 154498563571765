/* eslint-disable jsx-a11y/anchor-is-valid */
import apertura from '../assets/images/news/new-03/apertura-vortex-poster-club-min.png';
import buenosVecinos from '../assets/images/news/new-02/buenos-vecinos-poster-vortex-club-min.png';
import presentacion from '../assets/images/news/new-01/presentacion-vortex-poster-club-min.png';

import { Link } from 'react-router-dom';

export default function News() {
  return (
    <section className="section news" id="news" aria-label="sección noticias">
        <div className="container">

          <h2 className="h2 section__title">
            Últimas <span className="span">Noticias</span>
          </h2>

          <p className="section__text">
          Las últimas noticias que están transformando nuestro club. En esta sección, te mantenemos al día con todo lo que está sucediendo.
          </p>

          <ul className="news__list">

          <li>
              <div className="news__card">

                <figure className="card__banner img__holder" style={{"--width": "400", "--height": "290"}}>
                  <img
                    src={apertura}
                    width="400"
                    height="290"
                    loading="lazy"
                    alt="vortex club noticias, inauguración, apertura 15 de diciembre, deportivo, king of banana, acto del Ministerio de Turismo, Cultura y Deportes, junto a la Secretaría de Deportes, Guido Romero, Pablo Tabachnik, Rubén Arroyo, Rivadavia, San Juan."
                    className="img__cover"
                    />
                </figure>

                <div className="card__content">

                  <ul className="card__meta__list">

                    <li className="card__meta__item">
                      <ion-icon name="person-outline"></ion-icon>

                      <a href="#" className="item__text">Comunicación Vortex</a>
                    </li>

                    <li className="card__meta__item">
                      <ion-icon name="calendar-outline"></ion-icon>

                      <time dateTime="2023-12-15" className="item__text">15-12-2023</time>
                    </li>

                  </ul>

                  <h3>
                    {/* <a href="#" className="card__title">INAUGURAMOS NUESTRO CLUB, GRAN APERTURA</a> */}
                    <Link to="/noticias/gran-apertura-15-de-diciembre-club-vortex-deportivo" 
                    className="card__title">INAUGURAMOS NUESTRO CLUB, GRAN APERTURA</Link>
                  </h3>

                  <p className="card__text">
                  Nuestra apertura tras una emocionante fase de renovación.
                  </p>
                    {/* 
                  <a href="#" className="card__link">
                    <span className="span">Leer Más</span>

                    <ion-icon name="add-outline"></ion-icon>
                  </a> */}

                  <Link to="/noticias/gran-apertura-15-de-diciembre-club-vortex-deportivo" className="card__link">
                    <span className="span">Leer Más</span>

                    <ion-icon name="add-outline"></ion-icon>
                  </Link>

                </div>

              </div>
            </li>

            <li>
              <div className="news__card">

                <figure className="card__banner img__holder" style={{"--width": "400", "--height": "290"}}>
                  <img
                    src={buenosVecinos}
                    width="400"
                    height="290"
                    loading="lazy"
                    alt="vortex club noticias, Ya somos un vecino más, Buenos Vecinos, Iniciamos campañas de acercamiento a la comunidad."
                    className="img__cover"
                    />
                </figure>

                <div className="card__content">

                  <ul className="card__meta__list">

                    <li className="card__meta__item">
                      <ion-icon name="person-outline"></ion-icon>

                      <a className="item__text">Comunicación Vortex</a>
                    </li>

                    <li className="card__meta__item">
                      <ion-icon name="calendar-outline"></ion-icon>

                      <time dateTime="2023-11-09" className="item__text">09-11-2023</time>
                    </li>

                  </ul>

                  <h3>
                    {/* <a href="#" className="card__title">Ya somos un vecino más, Buenos Vecinos</a> */}
                    <Link to="/noticias/buenos-vecinos-iniciamos-campañas-de-acercamiento-a-la-comunidad" className="card__title">Ya somos un vecino más, Buenos Vecinos</Link>
                  </h3>

                  <p className="card__text">
                  Iniciamos campañas de acercamiento a la comunidad.
                  </p>

                  {/* <a href="#" className="card__link">
                    <span className="span">Leer Más</span>

                    <ion-icon name="add-outline"></ion-icon>
                  </a> */}

                  <Link to="/noticias/buenos-vecinos-iniciamos-campañas-de-acercamiento-a-la-comunidad" className="card__link">
                    <span className="span">Leer Más</span>

                    <ion-icon name="add-outline"></ion-icon>
                  </Link>

                </div>

              </div>
            </li>

            <li>
              <div className="news__card">

                <figure className="card__banner img__holder" style={{"--width": "400", "--height": "290"}}>
                  <img
                    src={presentacion}
                    width="400"
                    height="290"
                    loading="lazy"
                    alt="Vortex club noticias, Presentamos nuestro Club en Conferencia de Prensa, Con este lanzamiento, marcamos un hito emocionante en la historia de San Juan."
                    className="img__cover"
                    />
                </figure>

                <div className="card__content">

                  <ul className="card__meta__list">

                    <li className="card__meta__item">
                      <ion-icon name="person-outline"></ion-icon>

                      <a className="item__text">Comunicación Vortex</a>
                    </li>

                    <li className="card__meta__item">
                      <ion-icon name="calendar-outline"></ion-icon>

                      <time dateTime="2023-10-11" className="item__text">11-10-2023</time>
                    </li>

                  </ul>

                  <h3>
                    {/* <a href="#" className="card__title">Presentamos nuestro Club en Conferencia de Prensa</a> */}
                    <Link to="/noticias/presentamos-nuestro-club-en-conferencia-de-prensa" className="card__title">Presentamos nuestro Club en Conferencia de Prensa</Link>
                  </h3>

                  <p className="card__text">
                  Con este lanzamiento, marcamos un hito emocionante en la historia de San Juan.
                  </p>

                  {/* <a href="#" className="card__link skew">
                    <span className="span">Leer Más</span>

                    <ion-icon name="add-outline"></ion-icon>
                  </a> */}
                  
                  <Link to="/noticias/presentamos-nuestro-club-en-conferencia-de-prensa" className="card__link ">
                    <span className="span">Leer Más</span>

                    <ion-icon name="add-outline"></ion-icon>
                  </Link>

                </div>

              </div>
            </li>

            {/* <li>
              <div className="news__card">

                <figure className="card__banner img__holder" style={{"--width": "400", "--height": "290"}}>
                  <img
                    src={{}}
                    width="400"
                    height="290"
                    loading="lazy"
                    alt=""
                    className="img__cover"
                    />
                </figure>

                <div className="card__content">

                  <ul className="card__meta__list">

                    <li className="card__meta__item">
                      <ion-icon name="person-outline"></ion-icon>

                      <a href="#" className="item__text">Comunicación Vortex</a>
                    </li>

                    <li className="card__meta__item">
                      <ion-icon name="calendar-outline"></ion-icon>

                      <time dateTime="2023-00-00" className="item__text">00-00-2023</time>
                    </li>

                  </ul>

                  <h3>
                    <a href="#" className="card__title">Título</a>
                  </h3>

                  <p className="card__text">
                    Subtítulo
                  </p>

                  <a href="#" className="card__link">
                    <span className="span">Leer Más</span>

                    <ion-icon name="add-outline"></ion-icon>
                  </a>

                </div>

              </div>
            </li> */}

          </ul>

        </div>
      </section> 
  )
}
