import { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  XIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon
} from "react-share";

import Footer from "../Footer";

import image01 from '../../assets/images/news/new-02/buenos-vecinos-vortex-club-01-min.png';
import image02 from '../../assets/images/news/new-02/buenos-vecinos-vortex-club-02-min.png';
import image03 from '../../assets/images/news/new-02/buenos-vecinos-vortex-club-03-min.png';

export default function NewOne() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const shareUrl = 'https://vortexclubdeportivo.com.ar/noticias/buenos-vecinos-iniciamos-campañas-de-acercamiento-a-la-comunidad';
  const shareTitle = 'Ya somos un vecino más, iniciamos campañas de acercamiento a la comunidad.';


  return (
    <>
    <section className="activities">

      <div className="container">

        <img
          className="img__cover img__holder"
          loading="lazy"
          src={image01}
          alt="vortex club noticias buenos vecinos capital, rivadavia, rawson san juan"
          style={{marginTop:"5rem", aspectRatio: "16/9"}}
          />

    <h1 className="section__title" style={{ color:"black", marginTop:"100px" }}>
      Ya somos un vecino más
    </h1>
    <h2>
    Iniciamos campañas de acercamiento a la comunidad
    </h2>

    <p style={{textAlign:"start",color:"black", fontFamily:"inherit", fontSize:"1.8rem", paddingTop:"1.5rem", paddingBottom:"10rem"}}>
    En un esfuerzo genuino por fortalecer los lazos con la comunidad, realizamos una campaña de acercamiento a los vecinos de las zonas aledañas. Durante esta iniciativa, Ruben Arroyo, Presidente del Club y su equipo, visitaron los comercios cercanos y pudieron compartir con residentes locales información importante de nuestra institución.
    </p>

    <img
          className="img__cover img__holder"
          loading="lazy"
          src={image02}
          alt="vortex club deportivo noticias buenos vecinos capital, rivadavia, rawson san juan"
          />

    <p style={{textAlign:"start",color:"black", fontFamily:"inherit", fontSize:"1.8rem", paddingTop:"1.5rem", paddingBottom:"10rem"}}>
    El objetivo principal de esta campaña es informar a la comunidad sobre las actividades que se están llevando a cabo para dejar el club listo para su gran inauguración el 15 de diciembre y contarles lo felices que nos sentimos de ser parte de esta vecindad. En este proceso, es posible que los vecinos experimenten un aumento en el nivel de ruido, y es por eso que se ha considerado crucial comunicar este aspecto de manera transparente.

    Los representantes de Vortex destacaron los servicios que ofrecerá el club una vez que esté completamente operativo, resaltando sus valores fundamentales de sostenibilidad, respeto, deporte y un fuerte sentido de comunidad. En palabras del presidente del club, "Estamos felices de ser un vecino más en esta maravillosa zona y aspiramos a contribuir al bienestar de todos a través del deporte y la vida del club. Creemos en la importancia de establecer alianzas que nos permitan potenciarnos en equipo”.

    </p>

    <img
      className="img__cover img__holder"
      loading="lazy"
      src={image03}
      alt="vortex club noticias buenos vecinos capital, rivadavia, rawson, san martin san juan"
      />

    <p style={{textAlign:"start",color:"black", fontFamily:"inherit", fontSize:"1.8rem", paddingTop:"1.5rem", paddingBottom:"10rem"}}>

    Además, hicimos foco en que estamos abiertos a escuchar las inquietudes y preguntas de la comunidad. Tenemos habilitados nuestros canales de comunicación para que los vecinos puedan hacer consultas y expresar sus ideas, reforzando así el compromiso con la transparencia y la colaboración con la comunidad.

    Con esta campaña de acercamiento, queremos manifestar lo felices que somos de ser un vecino más en la zona y en que soñamos con que Club Deportivo Vortex sea un espacio de crecimiento donde todas las personas puedan además de realizar deportes, sentirlo como su casa.

    </p>

    <h4>Compartir en:</h4>
    <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '300px', gap:"0.5rem", marginBottom:"3rem" }}>


      <WhatsappShareButton url={shareUrl} title={shareTitle}>
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>

      <FacebookShareButton url={shareUrl} quote={shareTitle} hashtag="#vortexclub">
        <FacebookIcon size={32} round />
      </FacebookShareButton>

      <TwitterShareButton url={shareUrl} title={shareTitle}>
        <XIcon size={32} round />
      </TwitterShareButton>

      <TelegramShareButton url={shareUrl} title={shareTitle}>
        <TelegramIcon size={32} round />
      </TelegramShareButton>

      <LinkedinShareButton url={shareUrl} title={shareTitle} summary="Ya somos un vecino más, iniciamos campañas de acercamiento a la comunidad.">
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>

      <EmailShareButton url={shareUrl} subject={shareTitle} body="Ya somos un vecino más, iniciamos campañas de acercamiento a la comunidad.">
        <EmailIcon size={32} round />
      </EmailShareButton>


    </div>

    <Link to="/" className="btn contact__wrapper-btn skewBg">
      Volver al inicio
    </Link>

    </div>
    </section>
    <Footer />
    </>
  )
}
