import { useEffect, useState, useCallback } from 'react';
import logo from '../assets/images/logo-navbar.png'

export default function Navbar() {
  
  const [isHeaderActive, setIsHeaderActive] = useState(false);
  const [isNavbarActive, setIsNavbarActive] = useState(false);

  const activeElemOnScroll = () => {
    if (window.scrollY > 100) {
      setIsHeaderActive(true);
    } else {
      setIsHeaderActive(false);
    }
  };

  const toggleNavbar = useCallback(() => {
    setIsNavbarActive(!isNavbarActive);
  }, [isNavbarActive]);

  const closeNavbar = () => {
    setIsNavbarActive(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', activeElemOnScroll);

    return () => {
      window.removeEventListener('scroll', activeElemOnScroll);
    };
  }, []);

  useEffect(() => {
    const navTogglers = document.querySelectorAll("[data-nav-toggler]");
    const navbarLinks = document.querySelectorAll("[data-nav-link]");
    
    const handleNavTogglerClick = () => {
      toggleNavbar();
    };

    const handleNavbarLinkClick = () => {
      closeNavbar();
    };

    navTogglers.forEach((navToggler) => {
      navToggler.addEventListener('click', handleNavTogglerClick);
    });

    navbarLinks.forEach((navbarLink) => {
      navbarLink.addEventListener('click', handleNavbarLinkClick);
    });

    return () => {
      navTogglers.forEach((navToggler) => {
        navToggler.removeEventListener('click', handleNavTogglerClick);
      });

      navbarLinks.forEach((navbarLink) => {
        navbarLink.removeEventListener('click', handleNavbarLinkClick);
      });
    };
  }, [toggleNavbar]);


  return (

    <header className={`header ${isHeaderActive ? 'active' : ''}`}>

    <div className="header__bottom" data-header>

      <div className="container">

        <a href="/" className="logo">
          <img src={logo} alt="vortex" style={{height: "max-content", width:"18rem"}}/>
        </a>

        <nav className={`navbar ${isNavbarActive ? 'active' : ''}`} data-navbar>
          <ul className="navbar__list">

            <li className="navbar__item">
              <a href="#home" className="navbar__link" data-nav-link>Inicio</a>
            </li>

            <li className="navbar__item">
              <a href="#activities" className="navbar__link" data-nav-link>Actividades</a>
            </li>

            <li className="navbar__item">
              <a href="#benefits" className="navbar__link" data-nav-link>Beneficios</a>
            </li>

            <li className="navbar__item">
              <a href="#news" className="navbar__link" data-nav-link>Noticias</a>
            </li>

            <li className="navbar__item">
              <a href="#gallery" className="navbar__link" data-nav-link>Galería</a>
            </li>

            <li className="navbar__item">
              <a href="#about" className="navbar__link" data-nav-link>Nosotros</a>
            </li>

            <li className="navbar__item">
              <a href="#contact" className="navbar__link" data-nav-link>Contacto</a>
            </li>

          </ul>
        </nav>

        <div className="header__actions">

          <button className="nav__toggle__btn" aria-label="menu" data-nav-toggler>
            <ion-icon name="menu-outline" className="menu"></ion-icon>
            {/* <ion-icon name="close-outline" className="close"></ion-icon> */}
          </button>

        </div>

      </div>
    </div>

  </header>
  )
}
