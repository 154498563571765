export default function About() {
  return (
    <section className="section about" id="about" aria-label="sección acerca de nosotros">
          <div className="container">

            {/* <p className="section__subtitle">Nuestra Historia</p> */}
            <p className="section__subtitle">Para la comunidad sanjuanina</p>

            <h2 className="h2 section__title">
              Nuestro  <span className="span">Legado</span>
            </h2>

            {/* <p className="section__text">
              Nuestra pasión es crear una comunidad donde el deporte, la diversión y el respeto se fusionan en una experiencia única. Fundado con la visión de promover un estilo de vida activo y saludable . Desde competiciones hasta eventos sociales, Vortex es mucho más que un club deportivo; es un lugar donde los sueños deportivos cobran vida y las amistades perduran. Descubre quiénes somos, lo que hacemos y cómo puedes unirte a nuestra comunidad apasionada.
            </p> */}

            <p className="section__text">
             En Club Vortex, queremos poner en valor la vida en el club e inspirar a todas las personas a volver a apasionarse por el deporte. Queremos ser un espacio donde las amistades que se construyan, perduren a lo largo del tiempo.
            Buscamos promover el crecimiento y el desarrollo personal y en equipo, no solo en lo deportivo, sino también en valores que para nosotros son fundamentales.
            Estamos comprometidos en dejar una huella duradera en San Juan, construyendo un futuro en comunidad.
            </p>

          </div>
    </section>
  )
}
