
export default function Aboutdos() {
    return (
      <section className="section about" id="about" aria-label="Nuestros Valores" style={{marginTop:"-100px"}}>
            <div className="container">

              {/* <p className="section__subtitle">Para la comunidad sanjuanina</p> */}
  
              <h2 className="h2 section__title">
                Nuestros  <span className="span">Valores</span>
              </h2>
  
              <p className="section__text">
              Nuestro legado se basa en cuatro valores esenciales que guían cada una de nuestras acciones en la comunidad sanjuanina.
              Son la columna vertebral de nuestro legado en la comunidad. En Club Vortex, nos esforzamos por dejar una huella basada en el respeto, la seguridad, la comunidad, y el deporte, y creemos que esto sólo es posible con el compromiso de todos.
              </p>
  
            </div>
      </section>
    )
  }
  