
export default function Social() {
  return (
    <div className="social__container">

     <div className="social__media">
      <div className="container">

        <div className="social-wrapper">

          <p className="social-title">Siguenos en :</p>

          <ul className="social-list">

            <li>
              <a
                href="https://www.facebook.com/vortexclubdeportivo"
                rel="noopener noreferrer"
                target="_blank"
                className="social-link"
                style={{ borderRadius: '50%', fontSize: 'xx-large' }}>
                <ion-icon name="logo-facebook"></ion-icon>
              </a>
            </li>

            <li>
              <a
              href="https://instagram.com/vortexclubdeportivo"
              rel="noopener noreferrer"
              target="_blank"
              className="social-link"
              style={{ borderRadius: '50%', fontSize: 'xx-large' }}>
                <ion-icon name="logo-instagram"></ion-icon>
              </a>
            </li>

            <li>
              <a
              href="https://www.youtube.com/@VortexClubDeportivo"
              rel="noopener noreferrer"
              target="_blank"
              className="social-link"
              style={{ borderRadius: '50%', fontSize: 'xx-large' }}>
                <ion-icon name="logo-youtube"></ion-icon>
              </a>
            </li>
          </ul>

        </div>

      </div>
    </div>

  </div>
  )
}
