
import { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';

export default function Contact() {

  const form = useRef();
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [messageSent, setMessageSent] = useState(localStorage.getItem('messageSent') === 'true');

  useEffect(() => {
    if (messageSent) {
      // Si ya se ha enviado un mensaje previamente, mostrar un mensaje informativo
      setSuccessMessage('¡Mensaje enviado con éxito!');
      setErrorMessage(null);
    }
  }, [messageSent]);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    if (messageSent) {
      setErrorMessage('El formulario ya ha sido enviado.');
      return;
    }

    emailjs.sendForm('service_my1hpst', 'template_ef96yhg', form.current, 'AsrmlFFRApwz9aVSL')
      .then((result) => {
          // console.log(result.text);
          setLoading(false);

          form.current.reset();
          setSuccessMessage('¡Mensaje enviado con éxito!');
          setMessageSent(true);
          localStorage.setItem('messageSent', 'true');
      }, (error) => {
          // console.log(error.text);
          setLoading(false);

          setErrorMessage('¡Ups! Algo salió mal.');
          setSuccessMessage(null);
      });
  };

  return (
    <section className="section contact" id="contact" aria-label="sección contacto">
        <div className="container">

          <h2 className="h2 section__title">
            <span className="span">Contacto</span>
          </h2>

          <p className="section__text" style={{padding: "30px"}}>
          ¿Tienes alguna pregunta, comentario o sugerencia? No dudes en ponerte en contacto con nosotros. Nuestro equipo está listo para ayudarte en todo momento. Completa el formulario a continuación o utiliza los detalles de contacto proporcionados.
          </p>

          <div className="container">

            {/* <!-- <div className="contact__wrapper-card"> --> */}
            <div className="contact__wrapper">

  
              {/* <!-- <form action="" className="contact__wrapper-form"> --> */}
              <form
              ref={form} onSubmit={sendEmail}
              action="" className="" style={{ width: '100%', display: 'inline-table', margin: 'auto' }}>
  
                <div className="input-wrapper skewBg" style={{ marginBottom: '1rem' }}>
                  <input type="text" name="from_name" aria-label="nombre" placeholder="Nombre" required
                    className="email-field"/>
  
                  <ion-icon name="person-outline"></ion-icon>
                </div>
                <div className="input-wrapper skewBg" style={{ marginBottom: '1rem' }}>
                  <input type="number" name="phone" aria-label="numero de teéfono" placeholder="Teléfono" required className="email-field"/>
  
                  <ion-icon name="call-outline"></ion-icon>
                </div>
  
                  <div className="input-wrapper skewBg" style={{ marginBottom: '1rem' }}>
                    <input type="email" name="email_id" aria-label="email" placeholder="Email" required className="email-field"/>
        
                    <ion-icon name="mail-outline"></ion-icon>
                  </div>
                  <div className="input-wrapper skewBg" style={{ marginBottom: '1rem' }}>
                    <input type="text" name="subject" aria-label="asunto" placeholder="Asunto" required className="email-field"/>
        
                    <ion-icon name="information-circle-outline"></ion-icon>
                  </div>
        
                  <div className="input-wrapper skewBg" style={{ marginBottom: '1rem' }}>
                    {/* <!-- <input type="text" name="email_address" aria-label="email" placeholder="Asunto" required
                      className="email-field"> --> */}
                      
                      <textarea
                        name="message"
                        id=""
                        required
                        cols="100%"
                        rows="100%"
                        aria-label="mensaje"
                        className="email-field"
                        placeholder="Mensaje"
                        style={{
                            backgroundColor: 'inherit',
                            border: 'inherit',
                            width: '100%',
                            height: '100%',
                            fontSize: 'inherit',
                            fontFamily: 'inherit',
                        }}
                        ></textarea>
                      <ion-icon name="text-outline"></ion-icon>
        
                  </div>
  
                  {!successMessage && !errorMessage && !messageSent &&(
                <button
                  type="submit"
                  disabled={loading}
                  className="btn contact__wrapper-btn skewBg"
                  style={{ marginTottom: '2rem' }}
                  value="Send"
                  >
                  <span className="span">{loading ? "Enviando..." : "Enviar"}</span>
  
                  <ion-icon name="paper-plane" aria-hidden="true"></ion-icon>
                </button>
                )}

                {successMessage && (
                  <div
                    style={{ color: "white", backgroundColor: "green", padding: "10px", borderRadius: "5px", textAlign:"center"  }}
                    role="alert">
                    {successMessage}
                  </div>
                )}

                {errorMessage && (
                  <div
                    style={{ color: "white", backgroundColor: "red", padding: "10px", borderRadius: "5px", textAlign:"center"  }}
                    role="alert">
                    {errorMessage}
                  </div>
                )}
                
              </form>
  
            </div>
  
          </div>



        </div>
      </section>
  )
}
