import poster from '../assets/images/vortex-poster-1080.png'
import video  from '../assets/images/video-promocional-vortex.mp4'
import fenix from '../assets/images/ave-fenix.png'
// import mision from '../assets/images/frente.png'
// import vision from '../assets/images/dorsal.png'

export default function VideoBanner() {
  return (
    <section className="section video__banner">
          <div className="container"> 

             <h2 className="h2 section__title">
              Se Viene Algo <span className="span">Único</span>
            </h2>

            <figure className="video__banner-banner img__holder" style={{"--width": "800", "--height": "470"}}>

              <video
              src={video}
              width="800"
              height="470"
              loading="lazy"
              alt="video promocional vortex"
              className="img__cover"
              controls
              poster={poster}></video>

              {/* <!-- <img src="" width="800" height="470" loading="lazy"
                alt="" className="img__cover"> -->

              <!-- <button className="play-btn" aria-label="">
                <ion-icon name="play"></ion-icon>
              </button> --> */}

            </figure>

            {/* <div className="video__banner-player">

              <figure className="player player-1 img__holder" style={{"--width": "406", "--height": "277"}}>
                <img
                    src={mision}
                    width="406"
                    height="277"
                    loading="lazy"
                    alt="misión: Inspirar y promover el espíritu deportivo, fomentando un estilo de vida activo y saludable en una comunidad unida."
                    className="w-100"
                    />
              </figure>

              <div className="video__banner-detail">

                <p className="video__banner-subtitle">PROXIMAMENTE</p> */}

                {/* <time className="video__banner-time" datetime="08:30">08:30</time> */}
                
                {/* <h3 className="video__banner-time" style={{color:"var(--vortex-orange_75)"}}>TIENDA</h3>

              </div>

              <figure className="player player-2 img__holder" style={{"--width": "406", "--height": "277"}}>
                <img
                src={vision}
                width="400"
                height="305"
                loading="lazy"
                alt="visión: Transformar vidas a través del deporte y la camaradería, construyendo un mundo más saludable y unido."
                className="w-100"
                />
              </figure>

            </div> */}

            <img
              src={ fenix }
              height="auto"
              loading="lazy"
              alt="ave fenix vortex club"
              style={{margin:"auto", marginTop:"10rem", maxWidth:"100%", filter:"grayscale(80%) contrast(10%)", opacity:"0.1", marginBottom:"-120px"}}
            />

          </div>
    </section>
  )
}
