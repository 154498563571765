import React, { useState } from 'react';

export default function Accordion() {
  const [respetoChecked, setRespetoChecked] = useState(true);
  const [seguridadChecked, setSeguridadChecked] = useState(true);
  const [comunidadChecked, setComunidadChecked] = useState(true);
  const [deporteChecked, setDeporteChecked] = useState(true);

  return (
    <>
      <div className="accordion">
        <ul>
          <li>
            <input
              type="checkbox"
              checked={respetoChecked}
              onChange={() => setRespetoChecked(!respetoChecked)}
            />
            <i></i>
            <h2>RESPETO</h2>
            <p>
              El respeto es la base de nuestra comunidad. Respetamos a nuestros
              compañeros/as, entrenadores, socios, rivales deportivos y a
              nosotros mismos. Valoramos la diversidad y la inclusión, creando
              un ambiente en el que todos se sientan seguros y valorados.
            </p>
          </li>
          
          <li>
            <input
            type="checkbox"
            checked={seguridadChecked}
            onChange={() => setSeguridadChecked(!seguridadChecked)}
            />
            <i></i>
            <h2>SEGURIDAD</h2>
            <p>La seguridad de nuestros miembros y de nuestro patrimonio, está por encima de todo. Nos comprometemos a proporcionar un entorno seguro en todas nuestras actividades, donde la integridad física y emocional de cada individuo esté protegida y respetada en todo momento. El club es nuestra casa.</p>
          </li>
          <li>
            <input
            type="checkbox"
            checked={comunidadChecked}
            onChange={() => setComunidadChecked(!comunidadChecked)}
            />
            <i></i>
            <h2>COMUNIDAD</h2>
            <p>Valoramos la construcción de relaciones sólidas y duraderas. Promovemos un sentido de comunidad en el que las amistades se forjan, y donde apoyarnos mutuamente es un compromiso inquebrantable.</p>
          </li>
          <li>
            <input
            type="checkbox"
            checked={deporteChecked}
            onChange={() => setDeporteChecked(!deporteChecked)}
            />
            <i></i>
            <h2>DEPORTE</h2>
            <p>Creemos en el deporte como una herramienta fundamental de transformación social e individual. Nos esforzamos por el crecimiento y el desarrollo personal en todos los aspectos. Fomentamos la disciplina, la superación y la adquisición de valores sólidos.</p>
          </li>
        </ul>
      </div>
    </>
  );
}
