import { useEffect } from "react";
import { Link } from "react-router-dom"

import {
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  XIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon
} from "react-share";
import Footer from "../Footer"


import image01 from '../../assets/images/news/new-01/presentacion-vortex-club-01-min.png';
import image02 from '../../assets/images/news/new-01/presentacion-vortex-club-02-min.png';
import image03 from '../../assets/images/news/new-01/presentacion-vortex-club-03-min.png';

export default function NewTwo() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const shareUrl = 'https://vortexclubdeportivo.com.ar/noticias/presentamos-nuestro-club-en-conferencia-de-prensa';
  const shareTitle = 'Presentamos nuestro Club en Conferencia de Prensa.';

  return (
    <>
 <section className="activities">

<div className="container">

  <img
    className="img__cover img__holder"
    loading="lazy"
    src={image01}
    alt="vortex club, noticias Presentamos nuestro Club en Conferencia de Prensa."
    style={{marginTop:"5rem", aspectRatio: "16/9"}}
    />

<h1 className="section__title" style={{ color:"black", marginTop:"100px" }}>
Presentamos nuestro Club en Conferencia de Prensa
</h1>
<h2>
Con este lanzamiento, marcamos un hito emocionante en la historia de San Juan.
</h2>

<p style={{textAlign:"start",color:"black", fontFamily:"inherit", fontSize:"1.8rem", paddingTop:"1.5rem", paddingBottom:"10rem"}}>
Es un orgullo enorme presentar en sociedad a través de la Conferencia de Prensa, todo el trabajo que estamos llevando adelante desde hace un tiempo para dejar abierta a la comunidad la institución el próximo 15 de diciembre de este año. El evento estuvo dirigido por los anfitriones de Vortex: el presidente, Rubén Arroyo, el arquitecto a cargo de la obra, Rodrigo Arroyo, y el director deportivo, Marcos Arroyo, quienes fueron los encargados de brindar todos los detalles.

</p>

    <img
          className="img__cover img__holder"
          loading="lazy"
          src={image02}
          alt="vortex club deportivo, noticias Presentamos nuestro Club en Conferencia de Prensa."
          />

    <p style={{textAlign:"start",color:"black", fontFamily:"inherit", fontSize:"1.8rem", paddingTop:"1.5rem", paddingBottom:"10rem"}}>

Los líderes del club compartieron la visión de darle nueva vida a esta institución que estuvo inactiva durante mucho tiempo, y lo que significa volver a darle cuerda a un espacio tan emblemático de la provincia. Además, resaltaron los valores fundamentales que guiarán todas las acciones del club, como el respeto, el deporte, la comunidad y la sostenibilidad.

La conferencia también se centró en el legado al que aspiramos, como  ser un espacio de pasión por el deporte, donde las amistades perduren en el tiempo. “Buscamos promover el crecimiento personal y colectivo, no solo en lo deportivo, sino también en valores que mencionamos anteriormente y que son esenciales para nosotros”, remarcó Ruben, mostrándose muy emocionado por lo que vendrá.

</p>

    <img
      className="img__cover img__holder"
      loading="lazy"
      src={image03}
      alt="vortex club deportivo, noticias Presentamos nuestro Club en Conferencia de Prensa."
      />

    <p style={{textAlign:"start",color:"black", fontFamily:"inherit", fontSize:"1.8rem", paddingTop:"1.5rem", paddingBottom:"10rem"}}>

Los dirigentes del club anunciaron una amplia variedad de actividades deportivas que estarán disponibles para la comunidad como voley, fútbol, natación, hockey sobre patines y básquet. Además habrá una gran piscina, un amplio salón y muchas actividades más y la sostenibilidad será transversal a cada una de ellas.

“Nuestro plan de obras es una prioridad, por eso trabajamos día a día para que el club esté abierto a los socios con todo disponible en diciembre”, enfatizó Rodrigo Arroyo a cargo de la reestructuración del club. 

Agradecemos a los medios de comunicación que se unieron a la conferencia y nos acompañaron en este importante momento y a la comunidad que asistió. Estamos listos para escribir un nuevo capítulo de la historia del deporte en San Juan, construyendo un futuro en comunidad. ¡Estamos emocionados por lo que está por venir!

</p>

<h4>Compartir en:</h4>
    <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '300px', gap:"0.5rem", marginBottom:"3rem" }}>


      <WhatsappShareButton url={shareUrl} title={shareTitle}>
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>

      <FacebookShareButton url={shareUrl} quote={shareTitle} hashtag="#vortexclub">
        <FacebookIcon size={32} round />
      </FacebookShareButton>

      <TwitterShareButton url={shareUrl} title={shareTitle}>
        <XIcon size={32} round />
      </TwitterShareButton>

      <TelegramShareButton url={shareUrl} title={shareTitle}>
        <TelegramIcon size={32} round />
      </TelegramShareButton>

      <LinkedinShareButton url={shareUrl} title={shareTitle} summary="Presentamos nuestro Club en Conferencia de Prensa.">
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>

      <EmailShareButton url={shareUrl} subject={shareTitle} body="Presentamos nuestro Club en Conferencia de Prensa.">
        <EmailIcon size={32} round />
      </EmailShareButton>


    </div>

    <Link to="/" className="btn contact__wrapper-btn skewBg">
      Volver al inicio
    </Link>

    </div>
    </section>
    <Footer />
    </>
  )
}
